import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,2,4,3,5,6,7,8,9,10,11];

export const dictionary = {
		"/": [12],
		"/application_form/user": [~14],
		"/application_form/[token]": [~13],
		"/dashboard/applicants/details/[employee_id]": [~16],
		"/dashboard/applicants/details/[employee_id]/edit": [~17],
		"/dashboard/applicants/snapshot/[snapshot_id]": [~18],
		"/dashboard/applicants/[page]": [15,[2]],
		"/dashboard/employers/business/[employer_id]/new": [~20],
		"/dashboard/employers/info/[business_id]": [~21],
		"/dashboard/employers/info/[business_id]/new": [~22],
		"/dashboard/employers/job_openings/info/[job_opening_id]": [~24],
		"/dashboard/employers/job_openings/new": [~25],
		"/dashboard/employers/job_openings/[page]": [23,[4]],
		"/dashboard/employers/[page]": [19,[3]],
		"/dashboard/offices/edit/[office_id]": [~27],
		"/dashboard/offices/[page]": [26,[5]],
		"/dashboard/signupTokens/[page]": [28,[6]],
		"/dashboard/tags/[page]": [29,[7]],
		"/employee/DS160": [~30],
		"/employee/dashboard/[page]": [31,[8]],
		"/employee/profile": [~32],
		"/employer/business/info/[business_id]": [~34],
		"/employer/business/info/[business_id]/new": [35],
		"/employer/business/[employer_id]": [33,[9]],
		"/employer/dashboard/candidates/details/[employee_id]": [~37],
		"/employer/dashboard/candidates/[page]": [36,[10]],
		"/employer/dashboard/interviews/[page]": [38,[11]],
		"/employer/new/[employer_id]": [~39],
		"/employer/profile": [~40],
		"/employer/profile/edit": [~41],
		"/h2a_form_custom/[token]": [~42],
		"/profile/employee/[id]": [~43],
		"/success": [~44],
		"/user": [~45],
		"/user/complete_registration/[type]/[token]": [~46],
		"/user/forgot_password": [47],
		"/user/forgot_password/confirmed": [48],
		"/user/profile": [49],
		"/user/reset_password/[token]": [~50],
		"/user/signout": [~51]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';